import { InsightQueryType, PickerItem } from '@hazadapt-git/public-core-base'
import React, { FC, useMemo } from 'react'

import { PrepCheckInsightsTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { useAppSelector } from '../lib/store'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { useInsights } from '../lib/hooks/useInsights'
import { shallowEqual } from 'react-redux'

interface PrepCheckInsightsProps extends NamespacedPageProps {}

const PrepCheckInsights: FC<PrepCheckInsightsProps> = ({
    user,
    organization,
    loading,
}) => {
    const {
        insights,
        activeQuery,
        quickStatRowTitle,
        retrievingInsights,
        onReloadPress,
        onResetQueryPress,
        onRunQueryPress,
        onSaveQuery,
        selectedDatesString,
        onDownloadReportPress,
        selectedAreas,
        selectedAreaName,
        // TODO: Restore for insight sorting */
        // availableSortMethods,
        // setInsightSortMethod,
    } = useInsights({
        organization,
        type: InsightQueryType.PREP_CHECKS,
    })

    const prepChecks = useAppSelector(
        (state) => state.content.prepChecks,
        shallowEqual
    )

    React.useEffect(() => {
        document.title = 'Prep Check Activity - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    const buildSelectedQueryString = () => {
        const selectedPrepCheck = prepChecks.find(
            (pc) => pc.id === activeQuery?.prep_checks?.[0]
        )
        return selectedPrepCheck
            ? `${selectedPrepCheck.title} Prep Check`
            : 'All Prep Checks'
    }

    const prepCheckOptions: PickerItem<number | undefined>[] = useMemo(
        () => [
            { label: 'All Prep Checks', value: undefined },
            ...[...prepChecks]
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((pc) => ({ label: pc.title, value: pc.id })),
        ],
        [prepChecks]
    )

    return organization && user ? (
        <PrepCheckInsightsTemplate
            pageTitle="Prep Check Activity"
            organization={organization}
            queryItemTitle="Prep Check"
            queryItems={prepCheckOptions}
            selectedQueryItem={activeQuery?.prep_checks?.[0]}
            queryItemSelectorPlaceholder="All Prep Checks"
            selectedAreas={selectedAreas}
            areaSelectorPlaceholder="Full Jurisdiction"
            queryId={activeQuery?.id}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={onDownloadReportPress}
            selectedQueryString={buildSelectedQueryString()}
            selectedLocationString={selectedAreaName}
            selectedDatesString={selectedDatesString}
            minQueryDate={new Date(organization.date_created)}
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading || retrievingInsights}
            // TODO: Restore for insight sorting */
            // availableSortMethods={availableSortMethods}
            // setInsightSortMethod={setInsightSortMethod}
            // sortMethod={activeQuery?.sort_method}
        />
    ) : null
}

export default PrepCheckInsights
