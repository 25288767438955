import { AxiosResponse } from 'axios'
import Stripe from 'stripe'
import { isa } from '../api'
import { store } from '../store'
import { setNewPaymentMethodConfig } from '../slices/payment'

export const getNewPaymentMethodSession = async (
    subscription_id: string | null
): Promise<Stripe.SetupIntent> => {
    let response: AxiosResponse<Stripe.SetupIntent>

    if (subscription_id) {
        response = await isa.put(
            `/subscription/${subscription_id}/payment-method`
        )
    } else {
        response = await isa.post('/payment-method')
    }
    store.dispatch(
        setNewPaymentMethodConfig(response.data.client_secret ?? null)
    )
    return response.data
}
