import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../lib/store'
import { selectCart, setBuySeatsDrawerOpen } from '../../lib/slices/payment'
import { Cart, colors } from '@hazadapt-git/public-core-base'
import {
    clearCart,
    getAvailableAddons,
    getCart,
    overrideCart,
    useWindowSizeUp,
} from '../../lib/utils'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Counter } from '../atoms'
import { IoClose } from 'react-icons/io5'
import { DrawerMenu } from '../atoms/DrawerMenu'
import { makeStyles } from 'tss-react/mui'
import { useCart } from '../../lib/hooks/useCart'
import { shallowEqual } from 'react-redux'

interface AddSeatsDrawerProps {
    variant?: 'contained' | 'outlined' | 'text'
    buttonText: string
}

export const AddSeatsTriggerAndDrawer: FC<AddSeatsDrawerProps> = ({
    variant = 'contained',
    buttonText,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')
    const dispatch = useAppDispatch()
    const { handleSubmitCart, handleCartUpdate, updatingCart } = useCart()
    const { addons, buySeatsDrawerOpen } = useAppSelector(
        (state) => state.payment,
        shallowEqual
    )
    const cart = useAppSelector(selectCart)

    const originalCart = useRef<Cart>()

    useEffect(() => {
        getCart()
        getAvailableAddons()
    }, [])

    useEffect(() => {
        if (originalCart.current || !cart) return
        originalCart.current = { ...cart }
    }, [cart])

    const handleOpenDrawer = useCallback(() => {
        dispatch(setBuySeatsDrawerOpen(true))
    }, [dispatch])

    const handleCloseDrawer = useCallback(() => {
        dispatch(setBuySeatsDrawerOpen(false))
    }, [dispatch])

    const handleAddSeatsCancel = useCallback(async () => {
        if (originalCart.current) await overrideCart(originalCart.current)
        handleCloseDrawer()
    }, [handleCloseDrawer])

    const handleClearCart = useCallback(async () => {
        if (!cart || cart.items.length === 0) return
        await clearCart(cart)
    }, [cart])

    const submitCart = useCallback(async () => {
        await handleSubmitCart()
        handleCloseDrawer()
    }, [handleSubmitCart, handleCloseDrawer])

    const renderAddOns = useCallback(() => {
        return addons.map((addOn) => {
            const cartItem = cart?.items.find(
                (i) => i.stripe_product_id === addOn.product_id
            )
            return (
                <div className={localClasses.addOn} key={addOn.product_id}>
                    <Typography fontWeight={500}>{addOn.name}</Typography>
                    <Typography>
                        ${(addOn.price / 100).toFixed(2)} per seat
                    </Typography>
                    <div className={localClasses.counter}>
                        <Counter
                            value={cartItem?.quantity ?? 0}
                            min={0}
                            max={99}
                            onChange={async (val) =>
                                await handleCartUpdate(addOn, val)
                            }
                        />
                    </div>
                </div>
            )
        })
    }, [
        addons,
        cart,
        localClasses.addOn,
        localClasses.counter,
        handleCartUpdate,
    ])

    const drawerContent: ReactNode = useMemo(() => {
        return (
            <Box
                p="1rem 0"
                display="flex"
                flexDirection="column"
                height="100%"
                flexWrap="nowrap"
                position="relative"
            >
                <Box justifyContent="center" p="0 1rem">
                    <div className={localClasses.addonDrawerHeader}>
                        <Typography
                            component="h1"
                            variant={mdScreens ? 'h2' : 'h3'}
                            mb="2rem"
                        >
                            Seats and Add-Ons
                        </Typography>
                        <IoClose
                            size={'2rem'}
                            cursor={'pointer'}
                            onClick={handleCloseDrawer}
                        />
                    </div>
                    {renderAddOns()}
                    <Box my="1rem">
                        <Link onClick={handleClearCart}>Clear Cart</Link>
                    </Box>
                    <Typography>
                        <strong>Please note:</strong> Any additional seats that
                        are added at the end of checkout will remain empty until
                        used, to add a new user, please navigate to the
                        Organization Settings page, to complete setup.
                        Additionally, any unused seats would continue to be
                        charged to debit/credit card on file until
                        removed/deleted from your organization/account.
                    </Typography>
                </Box>
                <Box width="100%" position="absolute" bottom={0}>
                    <Divider sx={{ width: '100%', margin: 0 }} />
                    <Box p="1rem">
                        <Typography
                            component="h2"
                            variant={mdScreens ? 'h3' : 'h4'}
                            color={
                                updatingCart ? colors.grays.NIMBUS : '#0B7702'
                            }
                        >
                            Subtotal: $
                            {cart
                                ? cart.items
                                      .reduce(
                                          (acc, curr) =>
                                              (acc += curr.total_cost / 100),
                                          0
                                      )
                                      .toFixed(2)
                                : '0.00'}
                            {updatingCart && (
                                <CircularProgress
                                    size={'1rem'}
                                    sx={{ ml: '.5rem' }}
                                />
                            )}
                        </Typography>
                        <div className={localClasses.drawerButtons}>
                            <Button
                                variant="outlined"
                                onClick={handleAddSeatsCancel}
                                style={{ flex: 1 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={submitCart}
                                style={{ flex: 1 }}
                                disabled={!cart || cart.items.length === 0}
                            >
                                Checkout
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Box>
        )
    }, [
        localClasses.addonDrawerHeader,
        localClasses.drawerButtons,
        mdScreens,
        renderAddOns,
        handleClearCart,
        cart,
        handleAddSeatsCancel,
        handleCloseDrawer,
        submitCart,
        updatingCart,
    ])

    return (
        <>
            <Button
                onClick={handleOpenDrawer}
                variant={variant}
                size="large"
                sx={{ maxWidth: 'fit-content' }}
            >
                {buttonText}
            </Button>
            <DrawerMenu
                content={drawerContent}
                open={buySeatsDrawerOpen}
                onClose={handleCloseDrawer}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    counter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '10rem',
    },
    addonDrawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '1rem',
        justifyContent: 'space-between',
    },
    addOn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '2rem',
        flexWrap: 'wrap',
    },
    drawerButtons: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        paddingTop: '1rem',
        justifyContent: 'center',
    },
})
