import { RPUser, colors } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import { RPLogo } from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'
import { NavBarItem } from '../../lib/entities'
import HazAdaptLogo from './../../assets/images/HazAdaptLogoRW.svg'
import { NavLink } from 'react-router-dom'

interface MobileSiteNavProps {
    user?: RPUser
    onLogoPress(): void
    onToggleMenu(): void
    navItems: NavBarItem[]
    mobileMenuOpen: boolean
}

export const MobileSiteNav: FC<MobileSiteNavProps> = ({
    user,
    onLogoPress,
    onToggleMenu,
    navItems,
    mobileMenuOpen,
}) => {
    const { classes: localClasses } = useLocalStyles()

    const handleNavPress = (e: React.MouseEvent) => {
        const linkElement = e.currentTarget as HTMLElement
        if (linkElement.classList.contains('active')) {
            window.dispatchEvent(new Event('page-refresh'))
        }
    }

    const handleLinkPress = () => {
        window.location.href = 'https://hazadapt.com'
    }

    const handleAvatarPress = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    if (!user) return null

    return (
        <Drawer
            component="nav"
            onClick={onToggleMenu}
            open={mobileMenuOpen}
            anchor="right"
            sx={{
                '& .MuiDrawer-paper': {
                    width: '15rem',
                    backgroundColor: colors.primary.CERULEAN,
                    transition: 'all 0.225s ease',
                },
            }}
        >
            <Box
                className={localClasses.avatarContainer}
                onClick={handleAvatarPress}
            >
                <ProfileLinkWithAvatar
                    src={user.profile_picture_uri ?? undefined}
                    name={user.first_name}
                />
            </Box>
            <Box className={localClasses.logoContainer}>
                <RPLogo
                    onClick={() => onLogoPress()}
                    className={localClasses.rpLogo}
                />
            </Box>
            <Box className={localClasses.content}>
                {navItems
                    .filter((item) => !item.hide)
                    .map((item, index) => (
                        <NavLink
                            key={item.label}
                            to={item.path}
                            onClick={(e) => handleNavPress(e)}
                            className={localClasses.navLink}
                        >
                            {({ isActive }) => (
                                <>
                                    {item.icon && <>{item.icon}</>}
                                    <Typography
                                        component="span"
                                        className={localClasses.navLinkText}
                                    >
                                        {item.label}
                                        <Box
                                            className={
                                                localClasses.activeItemIndicator
                                            }
                                            sx={{
                                                backgroundColor: isActive
                                                    ? colors.grays.BLANC
                                                    : 'inherit',
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}
                        </NavLink>
                    ))}
            </Box>
            <Box className={localClasses.footer} onClick={handleLinkPress}>
                <Typography className={localClasses.footerText}>
                    Powered By
                </Typography>
                <img
                    src={HazAdaptLogo}
                    alt={'HazAdapt Logo'}
                    className={localClasses.footerLogo}
                />
            </Box>
        </Drawer>
    )
}

const useLocalStyles = makeStyles()({
    avatarContainer: {
        padding: '1.4rem 1rem',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    logoContainer: {
        display: 'flex',
        height: '2rem',
        justifyContent: 'start',
        marginTop: '7rem',
    },
    rpLogo: {
        cursor: 'pointer',
        height: '1.5rem',
        marginLeft: '1rem',
        width: 'auto',
    },
    content: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginLeft: '1rem',
        marginTop: '2rem',
    },
    footer: {
        alignItems: 'center',
        bottom: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: '.5rem',
        position: 'absolute',
        width: '100%',
    },
    footerText: {
        color: colors.grays.BLANC,
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.25rem',
        paddingRight: '1rem',
        whiteSpace: 'nowrap',
    },
    footerLogo: { height: '2rem', width: 'auto' },
    navLink: {
        color: colors.grays.BLANC,
        cursor: 'pointer',
        display: 'flex',
        gap: '.5rem',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
    },
    navLinkText: {
        color: 'inherit',
        fontWeight: 500,
    },
    activeItemIndicator: {
        boxSizing: 'border-box',
        height: '1.5px',
        width: '100%',
    },
})
