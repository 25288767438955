import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { FC, useState } from 'react'
import {
    ConfirmOrganizationLogoModal,
    ConfirmProfilePictureModal,
    ConfirmSkipSetupModal,
    InsightGrid,
    SiteHeader,
    TrendingHazardList,
    QueryBuilder,
    PermissionsList,
    SiteFooter,
    AddTeamMemberModal,
    TeamMemberConfigModal,
    ViewJurisdictionModal,
    InsightQueryTable,
    SurveyAnswerInsightView,
    ManageProfileView,
    QueryBuilderSkeleton,
    DesktopSiteNav,
    MobileSiteNav,
} from '../organisms'
import ReactLogo from '../../logo.svg'
import {
    InsightQueryType,
    RPUser,
    RPUserPermission,
} from '@hazadapt-git/public-core-base'
import {
    INSIGHTS,
    ORGANIZATION,
    PRIMARY_RP_USER,
    SECONDARY_RP_USER,
    SURVEY_ANSWER_INSIGHT,
    TRENDING_HAZARDS,
} from './data'
import { ProfileDetailsForm } from '../organisms/ProfileDetailsForm'
import { TeamMemberList } from '../organisms/TeamMemberList'
import { subDays } from 'date-fns'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { primaryIconSize } from '../../lib/styles/universal'
import { IoHome as HomeIcon } from 'react-icons/io5'
import HazardGuideIcon from '../../assets/images/material-symbols_book-outline.svg'
import PrepCheckIcon from '../../assets/images/ph_shield-bold.svg'
import { MdOutlineHistory as SavedQueriesIcon } from 'react-icons/md'

interface OrganismsProps {}

export const Organisms: FC<OrganismsProps> = (props: OrganismsProps) => {
    const [pickerOneValue, setPickerOneValue] = useState<string>('')
    const [pickerTwoValue, setPickerTwoValue] = useState<string[]>([])
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [confirmSkipOpen, setConfirmSkipOpen] = useState<boolean>(false)
    const [permissions] = useState<RPUserPermission[]>([])

    // AddTeamMemberModal
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')

    // ConfirmOrganizationLogoModal
    const [logoDialogOpen, setLogoDialogOpen] = useState<boolean>(false)
    const [logoCropperImage, setLogoCropperImage] = React.useState(ReactLogo)

    // ConfirmProfilePictureModal
    const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(false)
    const [profileCropperImage, setProfileCropperImage] =
        React.useState(ReactLogo)

    // TeamMemberConfigModal
    const [editedRPUser, setEditedRPUser] = useState<RPUser>(SECONDARY_RP_USER)
    const [configModalOpen, setConfigModalOpen] = useState<boolean>(false)

    // ViewJurisdictionModal
    const [jurisdictionOpen, setJurisdictionOpen] = useState<boolean>(false)

    // ProfilePictureForm
    const [profilePicture, setProfilePicture] = useState<string>()

    // SiteNav
    const [desktopMenuOpen, setDesktopMenuOpen] = React.useState<boolean>(false)
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false)

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        permissions: [],
    }
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(2, 'Too short')
            .required('First Name is required'),
        last_name: Yup.string()
            .min(2, 'Too short')
            .required('Last Name is required'),
        email: Yup.string()
            .strict(true)
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Invalid email address'
            )
            .required('Email is required'),
    })

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AddTeamMemberModal
                </Typography>
                <Button onClick={() => setAddMemberOpen(true)}>
                    Add Member
                </Button>
                <AddTeamMemberModal
                    open={addMemberOpen}
                    onClose={() => setAddMemberOpen(false)}
                    onSubmit={() => setAddMemberOpen(false)}
                    newUser={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        permissions: [],
                    }}
                    updateNewUser={() => {}}
                    activeUser={PRIMARY_RP_USER}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ConfirmOrganizationLogoModal
                </Typography>
                <Button onClick={() => setLogoDialogOpen(true)}>
                    Confirm Logo
                </Button>
                <ConfirmOrganizationLogoModal
                    open={logoDialogOpen}
                    title="Confirm Logo"
                    selectedImage={logoCropperImage}
                    onImageChange={setLogoCropperImage}
                    onCancelClick={() => setLogoDialogOpen(false)}
                    onSaveClick={() => {}}
                    onClose={() => setLogoDialogOpen(false)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ConfirmProfilePictureModal
                </Typography>
                <Button onClick={() => setProfileDialogOpen(true)}>
                    Confirm Profile Picture
                </Button>
                <ConfirmProfilePictureModal
                    open={profileDialogOpen}
                    selectedImage={profileCropperImage}
                    onImageChange={setProfileCropperImage}
                    onSaveClick={() => {}}
                    onClose={() => setProfileDialogOpen(false)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ConfirmSkipSetupModal
                </Typography>
                <Button onClick={() => setConfirmSkipOpen(true)}>Skip</Button>
                <ConfirmSkipSetupModal
                    open={confirmSkipOpen}
                    onClose={() => setConfirmSkipOpen(false)}
                    onConfirmSkip={() => setConfirmSkipOpen(false)}
                    onSaveChanges={() => {}}
                />
                <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    InsightGrid
                </Typography>
                <InsightGrid data={INSIGHTS} />
                <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    InsightQueryTable
                </Typography>
                <InsightQueryTable
                    onDownloadReportPress={() => {}}
                    queries={[
                        {
                            user_name: `${PRIMARY_RP_USER.first_name} ${PRIMARY_RP_USER.last_name}`,
                            user_status: PRIMARY_RP_USER.status,
                            id: PRIMARY_RP_USER.user_id,
                            rp_user_id: PRIMARY_RP_USER.rp_user_id,
                            type: InsightQueryType.HAZARDS,
                            grid_config: {},
                            date_last_used: subDays(new Date(), 2),
                            insight_ids: [],
                            description: 'My Awesome First Query',
                        },
                        {
                            user_name: `${SECONDARY_RP_USER.first_name} ${SECONDARY_RP_USER.last_name}`,
                            user_status: SECONDARY_RP_USER.status,
                            id: SECONDARY_RP_USER.user_id,
                            rp_user_id: SECONDARY_RP_USER.rp_user_id,
                            type: InsightQueryType.CORE,
                            date_last_used: subDays(new Date(), 3),
                            user_profile_picture_uri:
                                SECONDARY_RP_USER.profile_picture_uri ??
                                undefined,
                            start_date: subDays(new Date(), 7),
                            end_date: subDays(new Date(), 4),
                            hazard_names: ['Flood', 'Tsunami'],
                            hazards: [1, 2],
                            zips: ['97333', '97331'],
                            grid_config: {},
                            insight_ids: [],
                            description: 'Second Query',
                        },
                    ]}
                />
                <InsightQueryTable onDownloadReportPress={() => {}} />
                <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    ManageProfileView
                </Typography>
                <ManageProfileView
                    firstName={firstName}
                    lastName={lastName}
                    title={title}
                    email={email}
                    hazadapt_id_email={email}
                    onFirstNameChange={setFirstName}
                    onLastNameChange={setLastName}
                    onTitleChange={setTitle}
                    onEmailChange={setEmail}
                    onImportClick={() => {}}
                    onChangeProfilePicture={setProfilePicture}
                    avatarOptions={[ReactLogo, ReactLogo, ReactLogo, ReactLogo]}
                    mode="setup"
                />
                <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    PermissionsList
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={() => {}}
                >
                    {(props) => (
                        <Form>
                            <PermissionsList
                                selectedPermissions={permissions}
                            />
                        </Form>
                    )}
                </Formik>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ProfileDetailsForm & ProfilePictureForm
                </Typography>
                <ProfileDetailsForm
                    firstName={firstName}
                    lastName={lastName}
                    title={title}
                    email={email}
                    hazadapt_id_email={email}
                    onFirstNameChange={setFirstName}
                    onLastNameChange={setLastName}
                    onTitleChange={setTitle}
                    onEmailChange={setEmail}
                    mode="manage"
                    onImportClick={() => {}}
                    onChangeProfilePicture={setProfilePicture}
                    avatarOptions={[ReactLogo, ReactLogo, ReactLogo, ReactLogo]}
                    profilePicture={profilePicture}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    QueryBuilder
                </Typography>
                <QueryBuilder
                    organization={ORGANIZATION}
                    pickerOneLabel="Hazard"
                    areaPickerLabel="Area"
                    pickerOneValue={pickerOneValue}
                    pickerOneOptions={[
                        { value: 'all', label: 'All Hazards' },
                        { value: '1', label: 'Wildfire' },
                        { value: '2', label: 'Hurricane' },
                    ]}
                    selectedAreas={pickerTwoValue}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={subDays(new Date(), 15)}
                    onRunQueryPress={(values) => {
                        setPickerOneValue(values.pickerOneValue)
                        setPickerTwoValue(values.pickerTwoValue)
                        setStartDate(values.start_date)
                        setEndDate(values.end_date)
                    }}
                    onResetQueryPress={() => {}}
                    onSaveQueryPress={() => {}}
                    onDownloadPress={() => {}}
                />
                <QueryBuilderSkeleton />
                <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    SiteHeader
                </Typography>
                <SiteHeader
                    user={PRIMARY_RP_USER}
                    onToggleMenu={() => setMobileMenuOpen(!mobileMenuOpen)}
                    hideHamburgerMenu={false}
                />
                <Divider />
            </Grid>
            <DesktopSiteNav
                navItems={[
                    {
                        label: 'Home',
                        path: '/',
                        icon: (
                            <HomeIcon
                                size={primaryIconSize}
                                style={{ paddingBottom: '1px' }}
                            />
                        ),
                    },
                    {
                        label: 'Hazard Guide Insights',
                        path: '/insights/hazards',
                        icon: (
                            <img
                                src={HazardGuideIcon}
                                alt="Hazard Guide Icon"
                                style={{
                                    height: primaryIconSize,
                                    marginBottom: '2px',
                                    width: primaryIconSize,
                                }}
                            />
                        ),
                    },
                    {
                        label: 'Prep Check Insights',
                        path: '/insights/prep-checks',
                        icon: (
                            <img
                                src={PrepCheckIcon}
                                alt="Prep Check Icon"
                                style={{
                                    height: primaryIconSize,
                                    marginBottom: '2px',
                                    width: primaryIconSize,
                                }}
                            />
                        ),
                    },
                    {
                        label: 'Saved Queries',
                        path: '/saved-queries',
                        icon: <SavedQueriesIcon size={primaryIconSize} />,
                    },
                ]}
                user={PRIMARY_RP_USER}
                onToggleMenu={() => setDesktopMenuOpen(!desktopMenuOpen)}
                onLogoPress={() => {}}
            />
            <MobileSiteNav
                navItems={[
                    {
                        label: 'Home',
                        path: '/',
                        icon: (
                            <HomeIcon
                                size={primaryIconSize}
                                style={{ paddingBottom: '1px' }}
                            />
                        ),
                    },
                    {
                        label: 'Hazard Guide Insights',
                        path: '/insights/hazards',
                        icon: (
                            <img
                                src={HazardGuideIcon}
                                alt="Hazard Guide Icon"
                                style={{
                                    height: primaryIconSize,
                                    marginBottom: '2px',
                                    width: primaryIconSize,
                                }}
                            />
                        ),
                    },
                    {
                        label: 'Prep Check Insights',
                        path: '/insights/prep-checks',
                        icon: (
                            <img
                                src={PrepCheckIcon}
                                alt="Prep Check Icon"
                                style={{
                                    height: primaryIconSize,
                                    marginBottom: '2px',
                                    width: primaryIconSize,
                                }}
                            />
                        ),
                    },
                    {
                        label: 'Saved Queries',
                        path: '/saved-queries',
                        icon: <SavedQueriesIcon size={primaryIconSize} />,
                    },
                ]}
                user={PRIMARY_RP_USER}
                mobileMenuOpen={mobileMenuOpen}
                onToggleMenu={() => setMobileMenuOpen(!mobileMenuOpen)}
                onLogoPress={() => {}}
            />
            <Grid item xs={12} display="flex" flexDirection="column">
                <Typography variant="h3" mb="1rem">
                    SiteFooter
                </Typography>
                <SiteFooter />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TeamMemberConfigModal
                </Typography>
                <Button onClick={() => setConfigModalOpen(true)}>
                    Edit Team Member
                </Button>
                <TeamMemberConfigModal
                    user={editedRPUser}
                    activeUser={PRIMARY_RP_USER}
                    onSaveChanges={(user) => {
                        setEditedRPUser(user)
                        setConfigModalOpen(false)
                    }}
                    open={configModalOpen}
                    onClose={() => setConfigModalOpen(false)}
                    mode="permissions"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    SurveyAnswerInsightView
                </Typography>
                <SurveyAnswerInsightView
                    organization={ORGANIZATION}
                    queryItemTitle=""
                    queryItems={[]}
                    startDate={startDate}
                    endDate={endDate}
                    onReloadPress={() => {}}
                    onDownloadPress={() => {}}
                    onResetQueryPress={() => {}}
                    onRunQueryPress={() => {}}
                    onSaveQueryPress={() => {}}
                    onDetailsPress={() => {}}
                    desktopMenuOpen={false}
                    selectedArea={[]}
                    selectedLocationString=""
                    selectedDatesString=""
                    minQueryDate={new Date(ORGANIZATION.date_created)}
                    onBack={() => {}}
                    onNextQuestionPress={() => {}}
                    onPrevQuestionPress={() => {}}
                    onSwitchMapColorTheme={() => {}}
                    data={SURVEY_ANSWER_INSIGHT}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TeamMemberList
                </Typography>
                <TeamMemberList
                    activeUser={PRIMARY_RP_USER}
                    members={[PRIMARY_RP_USER, SECONDARY_RP_USER]}
                    seats={5}
                    onAddUserClick={() => setAddMemberOpen(true)}
                    onOpenConfirmSkipSetupModal={() => {}}
                    onSaveUserChanges={() => {}}
                    onRemoveUser={() => {}}
                    onTransferOwnership={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TrendingHazardList
                </Typography>
                <TrendingHazardList
                    data={TRENDING_HAZARDS}
                    onPress={function (id: number): void {
                        throw new Error('Function not implemented.')
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ViewJurisdictionModal
                </Typography>
                <Button onClick={() => setJurisdictionOpen(true)}>
                    View Jurisdiction
                </Button>
                <ViewJurisdictionModal
                    open={jurisdictionOpen}
                    onClose={() => setJurisdictionOpen(false)}
                    jurisdiction={ORGANIZATION.jurisdiction}
                />
                <Divider />
            </Grid>
        </Grid>
    )
}
