import Typography from '@mui/material/Typography'
import { FC } from 'react'

interface LocalContentDataDisclaimerProps {}

export const LocalContentDataDisclaimer: FC<
    LocalContentDataDisclaimerProps
> = () => (
    <Typography variant="h5" component="p">
        * NOTE: Data for your local safety information reflects usage both
        inside and outside of your jurisdiction. All other data represents
        activity within your jurisdiction.
    </Typography>
)
