import { useStripe, useElements } from '@stripe/react-stripe-js'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    CheckoutPageTemplate,
    LoadingView,
    StripeContainer,
} from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { useAppDispatch, useAppSelector } from '../lib/store'
import { getStripePaymentIntent, toast } from '../lib/utils'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useBilling } from '../lib/hooks/useBilling'
import { IoAlertCircle } from 'react-icons/io5'
import { errorColor, primaryIconSize } from '../lib/styles/universal'
import { resetStripeOptions } from '../lib/slices/payment'
import Stripe from 'stripe'
import { shallowEqual } from 'react-redux'

interface CheckoutPageProps extends NamespacedPageProps {}
const CheckoutPage: FC<CheckoutPageProps> = () => {
    const stripe = useStripe()
    const dispatch = useAppDispatch()
    const elements = useElements()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const retrievedPaymentMethods = useRef(false)
    const [showPaymentElement, setShowPaymentElement] = useState(true)
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<Stripe.PaymentMethod | null>(null)
    const stripeOptions = useAppSelector(
        (state) => state.payment.stripeOptions,
        shallowEqual
    )
    const pendingSubscription = useAppSelector(
        (state) => state.payment.pendingSubscription,
        shallowEqual
    )

    const { paymentMethods, confirmPayment } = useBilling()

    useEffect(() => {
        document.title = 'Checkout - ResiliencePoint'
    }, [])

    useEffect(() => {
        setShowPaymentElement(paymentMethods.length === 0)
    }, [paymentMethods])

    useEffect(() => {
        if (paymentMethods.length === 0 || retrievedPaymentMethods.current)
            return
        retrievedPaymentMethods.current = true
        setSelectedPaymentMethod(paymentMethods[0].repr)
    }, [paymentMethods])

    const onSubmit: React.FormEventHandler = useCallback(
        async (e) => {
            e.preventDefault()
            try {
                if (!stripe || !elements || !stripeOptions?.clientSecret) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return
                }

                await elements?.submit()

                const result = await confirmPayment({
                    stripe,
                    clientSecret: stripeOptions.clientSecret,
                    returnUrl:
                        searchParams.get('return_url') ??
                        `${window.location.origin}/checkout/success`,
                    elements,
                    selectedPaymentMethod: showPaymentElement
                        ? null
                        : selectedPaymentMethod,
                })

                if (result.error?.message) {
                    toast(
                        result.error.message,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                }

                // NOTE: Post-checkout redirect is handled as Stripe webhook
            } catch (err) {
                console.error(err)
            }
        },
        [
            elements,
            searchParams,
            stripe,
            stripeOptions,
            selectedPaymentMethod,
            showPaymentElement,
            confirmPayment,
        ]
    )

    const goBack = useCallback(() => {
        dispatch(resetStripeOptions())
        navigate(-1)
    }, [navigate, dispatch])

    const onSelectPaymentMethod = useCallback(
        (id: string) => {
            const selectedMethod = paymentMethods.find((pm) => pm.id === id)
            if (!selectedMethod) return
            setSelectedPaymentMethod(selectedMethod.repr)
        },
        [paymentMethods]
    )

    return (
        <CheckoutPageTemplate
            subscription={pendingSubscription}
            onSubmit={onSubmit}
            goBack={goBack}
            showPaymentElement={showPaymentElement}
            setShowPaymentElement={setShowPaymentElement}
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            onSelectPaymentMethod={onSelectPaymentMethod}
        />
    )
}

const CheckoutPageWrapper: FC<CheckoutPageProps> = ({
    loading,
    user,
    organization,
}: CheckoutPageProps) => {
    const stripeOptions = useAppSelector((state) => state.payment.stripeOptions)
    const navigate = useNavigate()

    useEffect(() => {
        getStripePaymentIntent().catch((err) => {
            console.error(err)
            navigate('/settings/subscriptions', { replace: true })
        })
    }, [navigate])

    const CheckoutView = useMemo(() => {
        const props: CheckoutPageProps = { loading, user, organization }
        const ready =
            !loading &&
            !!user &&
            !!organization &&
            !!stripeOptions?.clientSecret
        return ready ? (
            <StripeContainer options={stripeOptions}>
                <CheckoutPage {...props} />
            </StripeContainer>
        ) : (
            <LoadingView />
        )
    }, [stripeOptions, loading, user, organization])
    return CheckoutView
}
export default CheckoutPageWrapper
