import { InsightDemographicMode } from '@hazadapt-git/public-core-base'
import Typography from '@mui/material/Typography'
import NightShelterOutlinedIcon from '@mui/icons-material/NightShelterOutlined'
import HailIcon from '@mui/icons-material/Hail'
import { FC, ReactNode, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

interface InsightDemographicModeTagProps {
    mode: InsightDemographicMode
}

export const InsightDemographicModeTag: FC<InsightDemographicModeTagProps> = ({
    mode,
}) => {
    const { classes: localClasses } = useLocalStyles()

    const modeData: {
        text: ReactNode
        icon: ReactNode
    } = useMemo(() => {
        if (mode === 'residents')
            return {
                text: 'Residents',
                icon: <NightShelterOutlinedIcon />,
            }
        if (mode === 'visitors')
            return {
                text: 'Visitors',
                icon: <HailIcon />,
            }
        return {
            text: null,
            icon: null,
        }
    }, [mode])

    return (
        <div className={localClasses.container}>
            {modeData.icon}
            {modeData.text && (
                <Typography fontWeight={500}>{modeData.text}</Typography>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
    },
})
