import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import {
    FullScreenInsightView,
    InsightView,
    InsightViewProps,
    QuickStatInsightViewProps,
} from '../molecules'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import { customBorderRadius } from '../../lib/styles/universal'
import { QuickStatInsightList } from './QuickStatInsightList'
import { insightHasData, useWindowSizeUp } from '../../lib/utils'
// TODO: Restore for insight sorting
// import Typography from '@mui/material/Typography'
// import { Picker } from '../atoms'
// import { InsightSortMethod, PickerItem } from '@hazadapt-git/public-core-base'

interface InsightGridProps {
    data?: InsightViewProps[]
    quickStatRowTitle?: string
    // TODO: Restore for insight sorting
    // availableSortMethods?: PickerItem<InsightSortMethod>[]
    // setInsightSortMethod?: (sort_method: InsightSortMethod) => void
    // sortMethod?: InsightSortMethod
}

export const InsightGrid: FC<InsightGridProps> = ({
    data,
    quickStatRowTitle,
    // TODO: Restore for insight sorting
    // availableSortMethods,
    // setInsightSortMethod,
    // sortMethod,
}) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [currentInsightIndex, setCurrentInsightIndex] = useState<number>(0)
    const mdScreen = useWindowSizeUp('md')

    useEffect(() => {
        if (!mdScreen) setFullScreen(false)
    }, [mdScreen])

    useEffect(() => {
        // Disable background scrolling in fullscreen view
        document.body.style.overflow = fullScreen ? 'hidden' : ''
        return () => {
            document.body.style.overflow = ''
        }
    }, [fullScreen])

    const mapInsights = useMemo(
        () => data?.filter((d) => d.type === 'map') ?? [],
        [data]
    )

    const nonMapInsights = useMemo(
        () =>
            data?.filter((d) => d.type !== 'map' && d.type !== 'quick-stat') ??
            [],
        [data]
    )
    const quickStatInsights = useMemo(
        () => data?.filter((d) => d.type === 'quick-stat') ?? [],
        [data]
    )

    const visitorIndex = nonMapInsights.findIndex((insight) =>
        insight.placement_id.includes('visitor')
    )

    const handleOpenFullScreen = (insight: InsightViewProps, index: number) => {
        if (!insightHasData(insight) || !mdScreen) return
        setCurrentInsightIndex(index)
        setFullScreen(true)
    }
    const containerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

    const handleJumpToVisitors: React.MouseEventHandler = (e) => {
        e.preventDefault()
        const target = data?.find((item) =>
            item.placement_id.includes('visitor')
        )
        if (target) {
            const ref = containerRefs.current[target.placement_id]
            if (ref) {
                ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            <Box
                display="grid"
                gridTemplateColumns="1fr"
                gap={{ xs: '1rem', md: '2rem' }}
            >
                {!data ? (
                    <>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            sx={{
                                borderRadius: customBorderRadius,
                                height: {
                                    xs: '15rem',
                                    md: '20rem',
                                    xl: '25rem',
                                },
                            }}
                        />
                        <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            overflow="hidden"
                        >
                            {Array.from(Array(4)).map((_, index) => (
                                <Skeleton
                                    variant="rectangular"
                                    height="10rem"
                                    width="100%"
                                    sx={{
                                        borderRadius: customBorderRadius,
                                        minWidth: {
                                            xs: '10rem',
                                            md: '14rem',
                                        },
                                    }}
                                    key={`quick-stat-insight-loader-${
                                        index + 1
                                    }`}
                                />
                            ))}
                        </Box>
                        <Grid
                            container
                            spacing={{ xs: '1rem', md: '2rem' }}
                            alignItems="stretch"
                        >
                            {Array.from(Array(6)).map((_, index) => (
                                <Grid
                                    item
                                    key={`insight-loader-${index + 1}`}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="20rem"
                                        sx={{
                                            borderRadius: customBorderRadius,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        {mapInsights.map((insight) => (
                            <Box
                                key={insight.placement_id}
                                borderRadius="0.5rem"
                                boxShadow="0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)"
                                overflow="hidden"
                            >
                                <InsightView {...insight} />
                            </Box>
                        ))}
                        <QuickStatInsightList
                            data={
                                quickStatInsights as QuickStatInsightViewProps[]
                            }
                            title={quickStatRowTitle}
                        />
                        {/** // TODO: Restore for insight sorting */}
                        {/* {availableSortMethods ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <Typography>
                                    How would you like to sort your data?
                                </Typography>
                                <Picker
                                    id="insight-sorting-select"
                                    data={availableSortMethods}
                                    onChange={setInsightSortMethod}
                                    placeholder="Select a sorting method"
                                    value={sortMethod}
                                />
                                {sortMethod ===
                                InsightSortMethod.SEGMENT_RESIDENTS_VISITORS ? (
                                    <> */}
                        {nonMapInsights.some(
                            (nmi) => nmi.demographic_mode === 'visitors'
                        ) && (
                            // Only show "Jump to Visitor Insights" link if there are any visitor insights
                            <Link onClick={handleJumpToVisitors}>
                                Jump to Visitor Insights
                            </Link>
                        )}
                        {/** // TODO: Restore for insight sorting */}
                        {/* </>
                                ) : null} */}
                        {/* </div>
                        ) : null} */}
                        <Grid
                            container
                            spacing={{ xs: '1rem', md: '2rem' }}
                            alignItems="stretch"
                        >
                            {nonMapInsights.map((insight, index) => (
                                <React.Fragment key={insight.placement_id}>
                                    {index === visitorIndex && (
                                        <Grid item xs={12}>
                                            <Divider
                                                sx={{
                                                    margin: '0.5rem 0',
                                                    width: '100%',
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        key={insight.placement_id}
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        xl={3}
                                    >
                                        <Box
                                            height="100%"
                                            boxShadow="0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)"
                                            borderRadius="0.5rem"
                                            ref={(el) =>
                                                (containerRefs.current[
                                                    insight.placement_id
                                                ] = el as HTMLDivElement | null)
                                            }
                                        >
                                            <InsightView
                                                {...insight}
                                                onOpenFullScreen={() =>
                                                    handleOpenFullScreen(
                                                        insight,
                                                        index
                                                    )
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                        {fullScreen && (
                            <FullScreenInsightView
                                insights={nonMapInsights}
                                currentInsightIndex={currentInsightIndex}
                                onClose={() => setFullScreen(false)}
                            />
                        )}
                    </>
                )}
                <Link onClick={scrollToTop}>Back to top </Link>
            </Box>
        </Box>
    )
}
