import { colors, palette } from '@hazadapt-git/public-core-base'
import PaginationItem from '@mui/material/PaginationItem'
import {
    createTheme,
    Palette as MuiPalette,
    PaletteOptions as MuiPaletteOptions,
    SxProps,
    Theme,
} from '@mui/material'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'

declare module '@mui/material' {
    interface Palette {
        'prep-checks': MuiPalette['primary']
    }

    interface PaletteOptions {
        'prep-checks'?: MuiPaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        'prep-checks': true
    }
}

export const customHazTealColor = colors.secondary.BONDI // color for a selected tab and input titles on settings page
export const customDarkBlueColor = colors.primary.CERULEAN // our custom blue branding color
export const customBlackColor = colors.grays.CHARCOAL // our dark grey color
export const customLightGreyColor = colors.grays.SMOOTH // our off-white color
export const customMidGreyColor = colors.grays.NIMBUS // our mid-grey color
export const altHazRedColor = colors.primary.CORAL // a more vivid variant of our haz red
export const transparentBkgdColor = 'rgba(0,0,0,0)' // transparent color
export const successColor = colors.primary.WAIKATO // color for success icons
export const errorColor = colors.primary.CORAL // color for error icons
export const infoToastColor = colors.primary.BLUEBERRY // color for informational toast icons
export const errorTextColor = colors.grays.NOIR // color for error text
export const customBorderRadius = '1rem' // universal border radius
export const customRoundedContainerBorderRadius = '1rem' // border radius for a swoop
export const chevronSize = '1.5rem' // the size of a chevron icon
export const navHeaderIconSize = '1.875rem' // nav icon size
export const primaryIconSize = '1.5rem' // toast icon size
export const unavailableChipColor = colors.grays.NIMBUS // color for an unavailable but selected chip
export const unavailableChipTextColor = colors.grays.SILVER // color for the text of an unavailable but selected chip
export const prepCheckColor = colors.primary.HELIOTROPE
export const localContentColor = '#0A8200'
export const desktopMenuOpenWidth = '15rem' // width of desktop sidebar menu when open
export const desktopMenuClosedWidth = '3.5rem' // width of desktop sidebar menu when closed
export const defaultSingleBarLimit = 10 // default maximum number of bars rendered on SingleBarGraphInsights
export const defaultMultiBarLimit = 5 // default maximum number of bars rendered on MultiBarGraphInsights

export const applyColorToPickerSubcomponents = (
    color?: string,
    type: 'outlined' | 'filled' | 'standard' = 'outlined'
): SxProps<Theme> => ({
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&:active .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '.MuiSelect-icon': {
        color,
    },
    '&.MuiFilledInput-root.MuiInputBase-root': {
        backgroundColor:
            type === 'filled' ? `${colors.grays.BLANC}2A` : undefined,
    },
})

export const breakpoints = {
    xs: 0,
    sm: 568,
    md: 768,
    lg: 1200,
    xl: 1600,
}

export const theme: Theme = createTheme({
    breakpoints: {
        values: breakpoints,
    },
    typography: {
        fontFamily: [
            'Rubik',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            color: colors.grays.NOIR,
            lineHeight: '2.5rem',
        },
        h2: {
            fontSize: '1.75rem',
            fontWeight: 600,
            color: colors.grays.NOIR,
            lineHeight: '2.25rem',
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
            color: colors.grays.NOIR,
            lineHeight: '1.875rem',
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 500,
            color: colors.grays.NOIR,
            lineHeight: '1.5rem',
        },
        h5: {
            fontSize: '1rem',
            fontStyle: 'italic',
            color: colors.grays.NOIR,
            lineHeight: '1.25rem',
        },
        body1: {
            color: colors.grays.NOIR,
        },
        body2: {
            color: colors.grays.NOIR,
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
        },
    },
    palette: {
        primary: {
            main: customDarkBlueColor,
        },
        success: {
            main: successColor,
        },
        error: {
            main: altHazRedColor,
        },
        secondary: {
            main: customBlackColor,
        },
        'prep-checks': {
            main: colors.primary.HELIOTROPE,
            contrastText: colors.grays.BLANC,
            dark: palette['tertiary'][600],
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: '0 0 0 0',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                },
                outlined: {
                    boxSizing: 'border-box',
                    borderWidth: '0.1rem',
                    '&:hover': {
                        borderWidth: '0.1rem',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.secondary.LINKY,
                    cursor: 'pointer',
                    textDecorationColor: colors.secondary.LINKY,
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: '90%',
                    margin: '1rem',
                    borderColor: colors.grays.CUMULUS,
                    '&::before, &::after': {
                        borderTopColor: colors.grays.CUMULUS,
                        borderBottomColor: colors.grays.CUMULUS,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 0 0 0',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    paddingLeft: '0.375rem',
                    borderColor: 'inherit',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    backgroundColor: colors.grays.BLANC,
                },
                input: {
                    padding: '0.75rem 1rem',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '0.625rem',
                },
                bar: {
                    backgroundColor: colors.primary.WAIKATO,
                },
                colorPrimary: {
                    backgroundColor: colors.softTones.WAIKATO,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colors.secondary.VIRIDIAN,
                    borderRadius: '0.5rem',
                    padding: '0.375rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    color: colors.grays.BLANC,
                    margin: '0.5rem !important',
                },
                arrow: {
                    color: colors.secondary.VIRIDIAN,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '0.75rem 1rem',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: `translate(1rem, 0.75rem) scale(1)`,
                },
                shrink: {
                    transform: 'translate(14px, -9px) scale(0.75) !important',
                },
                outlined: {
                    transform: 'translate(1rem, 0.75rem) scale(1)',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '2rem',
                    padding: '0.5rem',
                },
            },
        },
        MuiPagination: {
            defaultProps: {
                siblingCount: 1,
                renderItem: (params) => (
                    <PaginationItem
                        {...params}
                        slots={{
                            previous: IoChevronBack,
                            next: IoChevronForward,
                        }}
                    />
                ),
                color: 'secondary',
                shape: 'rounded',
                size: 'medium',
            },
            styleOverrides: {
                root: {
                    '& .Mui-selected': {
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: '0.75rem 0',
                    '&.Mui-expanded': {
                        margin: '0.75rem 0',
                    },
                },
            },
        },
    },
})

export const useStyles = makeStyles()({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        position: 'relative',
        flex: 1,
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
        flex: 1,
    },
    body: {
        width: '100%',
        maxWidth: '90rem',
        margin: '0 auto',
    },
    pageWithNoTopPadding: {
        paddingTop: 0,
    },
    settingsPage: {
        gap: '2rem',
        flexDirection: 'row',
        padding: '2rem 1rem',
        [theme.breakpoints.down('md')]: {
            padding: '1rem',
            flexDirection: 'column',
        },
    },
    backgroundFill: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: customDarkBlueColor,
    },
    baseContainer: {
        flex: 1,
        backgroundColor: colors.grays.BLANC,
    },
    centeredText: {
        textAlign: 'center',
    },
    smallButton: {
        padding: '0.75rem',
        height: '2rem',
    },
    error: {
        borderColor: altHazRedColor,
        color: errorTextColor,
    },
    pageHeaderOverride: {
        paddingLeft: '1rem',
    },
    pageHeaderBothSidesOverride: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    pageHeaderBothSides: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    bottomRightRoundedContainer: {
        borderBottomRightRadius: customRoundedContainerBorderRadius,
    },
    bottomLeftRoundedContainer: {
        borderBottomLeftRadius: customRoundedContainerBorderRadius,
    },
    topRightRoundedContainer: {
        borderTopRightRadius: customRoundedContainerBorderRadius,
    },
    topLeftRoundedContainer: {
        borderTopLeftRadius: customRoundedContainerBorderRadius,
    },
    appCopyrightMessage: {
        alignItems: 'center',
        color: colors.grays.NOIR,
        textAlign: 'center',
    },
    appVersionText: {
        fontWeight: 500,
        color: colors.grays.NOIR,
        fontSize: '1.125rem',
        textAlign: 'center',
    },
    publicAppIcon: {
        height: '5rem',
        width: '5rem',
        borderRadius: '0.625rem',
    },
    profilePicture: {
        backgroundColor: transparentBkgdColor,
        height: '1.25rem',
        width: '1.25rem',
    },
    accessory: {
        borderColor: transparentBkgdColor,
        backgroundColor: colors.grays.BLANC,
        elevation: 5,
        borderRadius: customBorderRadius,
    },
    emergencyBtn: {
        width: '2.3rem',
        height: '2.3rem',
    },
    helpIcon: {
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
    },
    searchQuery: {
        fontWeight: 500,
        color: customDarkBlueColor,
    },
    unstyledButton: {
        all: 'unset',
        cursor: 'pointer',
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    insightGrid: {
        display: 'grid',
        gridAutoRows: '1fr',
    },
    insightView: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
        gap: '1rem',
    },
    insightWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100vw - 2rem)',
    },
    insightHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
        marginRight: '-1rem',
    },
    insightTitle: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'break-spaces',
    },
    insightCtas: {
        marginBottom: '1.5rem',
    },
    insightTitleAndElement: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '0.5rem 1.5rem 1.5rem',
    },
    insight: {
        width: '100%',
        display: 'flex',
        flex: 1,
    },
    insightActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    usageBanner: {
        padding: '0.75rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inlinePickerWithText: {
        display: 'table-cell',
        verticalAlign: 'middle',
        lineHeight: '2.375rem',
        [theme.breakpoints.up('md')]: {
            lineHeight: '3rem',
        },
    },
    mobileBanner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileBannerOpen: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mobileBannerClosed: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    square: {
        aspectRatio: 1,
    },
    outlinedButton: {
        borderStyle: 'solid',
        borderWidth: 1,
    },
    popover: {
        cursor: 'default',
        margin: '1rem',
        maxWidth: 'min(80vw, 30rem)',
    },
    queryGroup: {
        display: 'flex',
        width: '100%',
        gap: '0.5rem',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'flex-end',
        ':first-of-type': {
            marginRight: '1rem',
        },
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
    },
    paymentMethod: {
        padding: '1rem 2rem',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        border: `1px ${colors.grays.CUMULUS} solid`,
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
    },
    selectedPaymentMethod: {
        border: `2px ${colors.primary.CERULEAN} solid`,
    },
})
