import { FC, useMemo } from 'react'
import { useWindowSizeUp } from '../../lib/utils'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { SeatSubscriptionView } from '../molecules'
import {
    SimplifiedSeatSubscription,
    useBilling,
} from '../../lib/hooks/useBilling'
import { SubscriptionStatus } from '@hazadapt-git/public-core-base'

interface SeatSubscriptionTableProps {
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
}

export const SeatSubscriptionTable: FC<SeatSubscriptionTableProps> = ({
    onNavigateToCancelSeats,
}) => {
    const lgScreen = useWindowSizeUp('lg')
    const { simplifiedSeatSubscriptions: seatSubs } = useBilling()

    const sortedSeatSubs = useMemo(() => {
        const subs = [...seatSubs]
        return subs.sort((a, b) => {
            if (a.status === SubscriptionStatus.CANCELLED) return 1
            if (b.status === SubscriptionStatus.CANCELLED) return -1
            return 0
        })
    }, [seatSubs])

    const columns = lgScreen
        ? ['Purchase Date', 'Number of Seats', 'Renewal Date']
        : ['Number of Seats', 'Renewal Date']

    return (
        <>
            {sortedSeatSubs.length > 0 && (
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((col, i) => (
                                    <TableCell
                                        key={col
                                            .toLowerCase()
                                            .replaceAll(' ', '-')}
                                    >
                                        <Typography
                                            textAlign={
                                                i > 0 ? 'center' : 'left'
                                            }
                                            fontWeight={500}
                                        >
                                            {col}
                                        </Typography>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSeatSubs.map((s) => (
                                <SeatSubscriptionView
                                    key={`seat-sub-${s.id}`}
                                    seatSub={s}
                                    onNavigateToCancelSeats={
                                        onNavigateToCancelSeats
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}
