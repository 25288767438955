import Skeleton from '@mui/material/Skeleton'
import { Stack } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import { FC } from 'react'
import { useWindowSizeUp } from '../../../lib/utils'
import { InsightsPageButtonsSkeleton } from './InsightsPageButtonsSkeleton'
import { makeStyles } from 'tss-react/mui'
import { useStyles } from '../../../lib/styles/universal'

interface QueryBuilderSkeletonProps {
    detachedPickerLabels?: boolean
}

export const QueryBuilderSkeleton: FC<QueryBuilderSkeletonProps> = ({
    detachedPickerLabels,
}) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {lgScreens ? (
                <div className={classes.queryGroup}>
                    {[...Array(4)].map((_, idx) => (
                        <Stack key={idx} sx={{ flex: 1 }}>
                            {detachedPickerLabels && (
                                <Skeleton
                                    width={'2.5rem'}
                                    className={localClasses.pickerSkeletonLabel}
                                />
                            )}
                            <Skeleton
                                variant="rectangular"
                                className={localClasses.pickerSkeleton}
                            >
                                <DatePicker
                                    onChange={() => {}}
                                    value={new Date()}
                                />
                            </Skeleton>
                        </Stack>
                    ))}
                </div>
            ) : null}
            <InsightsPageButtonsSkeleton />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    pickerSkeleton: {
        borderRadius: '0.5rem',
        height: '3rem',
        minWidth: '100%',
    },
    pickerSkeletonLabel: {
        fontSize: '1.5rem',
        marginBottom: '.4rem',
    },
})
