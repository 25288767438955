import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { Atoms } from './Atoms'
import { Molecules } from './Molecules'
import { Organisms } from './Organisms'
import { BrowserRouter } from 'react-router-dom'

interface ShowcaseProps {}

export const Showcase: FC<ShowcaseProps> = (props: ShowcaseProps) => {
    const { classes: showcaseClasses } = useShowcaseClasses()
    return (
        <BrowserRouter>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Card className={showcaseClasses.container}>
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Atoms
                    </Typography>
                    <Atoms />
                </Card>

                <Card className={showcaseClasses.container}>
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Molecules
                    </Typography>
                    <Molecules />
                </Card>

                <Card className={showcaseClasses.container}>
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Organisms
                    </Typography>
                    <Organisms />
                </Card>
            </div>
        </BrowserRouter>
    )
}

const useShowcaseClasses = makeStyles()({
    container: {
        border: '1px #05446A solid',
        margin: '2rem',
        width: '99%',
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
})
