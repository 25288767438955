import { colors } from '@hazadapt-git/public-core-base'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import { FC } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { useStyles } from '../../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'

interface NewPaymentMethodButtonProps {
    onClick: React.MouseEventHandler
    className?: string
}

export const NewPaymentMethodButton: FC<NewPaymentMethodButtonProps> = ({
    onClick,
    className,
}) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    return (
        <button
            className={classNames(
                classes.unstyledButton,
                localClasses.addNewPaymentMethod,
                className
            )}
            onClick={onClick}
        >
            <Stack alignItems="center" gap="0.5rem" justifyContent="center">
                <IoAddCircleOutline size="2rem" color={colors.grays.NOIR} />
                <Typography color={colors.grays.CHARCOAL}>
                    Add New Payment Method
                </Typography>
            </Stack>
        </button>
    )
}

const useLocalStyles = makeStyles()({
    addNewPaymentMethod: {
        paddingRight: '2rem !important',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.grays.SILK,
        textAlign: 'center',
    },
})
