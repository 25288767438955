import { useNavigate } from 'react-router'
import { selectCart } from '../slices/payment'
import { useAppSelector } from '../store'
import { updateCart } from '../utils'
import { AddOn, Cart, CartItem } from '@hazadapt-git/public-core-base'
import { useState } from 'react'
import { shallowEqual } from 'react-redux'

export const useCart = () => {
    const [updatingCart, setUpdatingCart] = useState<boolean>(false)
    const cart = useAppSelector(selectCart, shallowEqual)
    const addons = useAppSelector((state) => state.payment.addons, shallowEqual)
    const navigate = useNavigate()

    const handleSubmitCart = async () => {
        if (!cart) return Promise.resolve()

        return updateCart(cart, cart.items, true)
            .then(() => {
                navigate('/checkout')
            })
            .catch(console.error)
    }

    const handleCartUpdate = async (addOn: AddOn, quantity: number) => {
        try {
            setUpdatingCart(true)

            const newCart: Cart = cart
                ? {
                      ...cart,
                      items: cart.items.filter((i) =>
                          addons.some(
                              (ao) => ao.product_id === i.stripe_product_id
                          )
                      ),
                  }
                : {
                      items: [],
                      date_created: new Date(),
                      date_updated: new Date(),
                  }
            const itemIndex = newCart.items.findIndex(
                (i) => i.stripe_product_id === addOn.product_id
            )
            if (itemIndex >= 0) {
                const item: CartItem = { ...newCart.items[itemIndex] }
                if (quantity === 0) newCart.items.splice(itemIndex, 1)
                else {
                    item.label = addOn.name
                    item.stripe_product_id = addOn.product_id
                    item.stripe_price_id = addOn.price_id
                    item.quantity = quantity
                    item.item_price = addOn.price
                    item.total_cost = addOn.price * quantity
                    newCart.items.splice(itemIndex, 1, item)
                }
                await updateCart(newCart, [item])
            } else if (quantity >= 1) {
                const item: CartItem = {
                    label: addOn.name,
                    stripe_product_id: addOn.product_id,
                    stripe_price_id: addOn.price_id,
                    quantity,
                    item_price: addOn.price,
                    total_cost: addOn.price * quantity,
                }
                newCart.items.push(item)
                await updateCart(newCart, [item])
            }
        } catch (err) {
            console.error('Error updating cart: ', err)
        } finally {
            setUpdatingCart(false)
        }
    }

    return { handleSubmitCart, handleCartUpdate, updatingCart }
}
