import React, { FC, useMemo, useState } from 'react'
import {
    DonutChartInsight,
    LineFillInsight,
    LineGraphInsight,
    MultiBarGraphInsight,
    MultiCounterInsight,
    QuickStatInsight,
    SingleBarGraphInsight,
    colors,
} from '@hazadapt-git/public-core-base'
import { BarGraphInsightView } from './BarGraphInsightView'
import { LineGraphInsightView } from './LineGraphInsightView'
import { MapInsightView, MapInsightViewProps } from './MapInsightView'
import {
    defaultMultiBarLimit,
    defaultSingleBarLimit,
    primaryIconSize,
    useStyles,
} from '../../lib/styles/universal'
import { MenuItemProps } from '../../lib/entities'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { IoEllipsisVertical, IoInformationCircle } from 'react-icons/io5'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import classNames from 'classnames'
import { DonutChartInsightView } from './DonutChartInsightView'
import { LineFillInsightView } from './LineFillInsightView'
import { MultiCounterInsightView } from './MultiCounterInsightView'
import { QuickStatInsightView } from './QuickStatInsightView'
import { Popover, Switch } from '../atoms'
import {
    insightHasData,
    isMultiBarGraphInsight,
    isSingleBarGraphInsight,
    useWindowSizeUp,
} from '../../lib/utils'
import { CustomMenuItem } from '../../lib/entities/misc'
import { InsightDemographicModeTag } from './InsightDemographicModeTag'
import { makeStyles } from 'tss-react/mui'

export type InsightViewBaseProps =
    | Omit<SingleBarGraphInsight, 'headline'>
    | Omit<MultiBarGraphInsight, 'headline'>
    | Omit<LineGraphInsight, 'headline'>
    | Omit<MapInsightViewProps, 'headline'>
    | Omit<DonutChartInsight, 'headline'>
    | Omit<LineFillInsight, 'headline'>
    | Omit<MultiCounterInsight, 'headline'>
    | Omit<QuickStatInsight, 'headline'>

export type InsightViewProps = InsightViewBaseProps & {
    placement_id: string
    headline: React.ReactNode
    handleCtaClick?(href: string): void
    options: MenuItemProps[]
    onOpenFullScreen?: () => void
}

export const InsightView: FC<InsightViewProps> = (props: InsightViewProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mdScreen = useWindowSizeUp('md')
    const [showAllData, setShowAllData] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    const handleShowAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllData(event.target.checked)
    }
    const showSwitch =
        props.type === 'bar' &&
        (isSingleBarGraphInsight(props)
            ? props.bars.length > defaultSingleBarLimit
            : isMultiBarGraphInsight(props)
            ? props.labels.length > defaultMultiBarLimit
            : false)

    const hasData = useMemo(() => insightHasData(props), [props])

    return (
        <div
            className={classes.insightView}
            onDoubleClick={() => props.onOpenFullScreen?.()}
        >
            <div className={classes.insightWrapper} id={props.placement_id}>
                {props.type === 'map' ? (
                    <MapInsightView {...props} />
                ) : (
                    <div className={classes.insightTitleAndElement}>
                        {props.type !== 'quick-stat' ? (
                            <Box
                                mb="1rem"
                                display="flex"
                                flexDirection="column"
                                gap="1rem"
                            >
                                <div className={classes.insightHeader}>
                                    <div
                                        className={classes.insightTitle}
                                        style={{
                                            flex: 1,
                                            justifyContent:
                                                props.headline === 'Insights'
                                                    ? 'center'
                                                    : undefined,
                                        }}
                                    >
                                        <Typography
                                            display="flex"
                                            alignItems="center"
                                            component="p"
                                            fontWeight={500}
                                            fontSize="1.125rem"
                                        >
                                            {props.headline}
                                        </Typography>
                                        {props.explainer ? (
                                            <Popover
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                icon={
                                                    <IoInformationCircle
                                                        color={
                                                            colors.grays.NIMBUS
                                                        }
                                                    />
                                                }
                                                helpText={props.explainer}
                                            />
                                        ) : null}
                                    </div>
                                    {hasData && (
                                        <div className={classes.insightActions}>
                                            <IconButton
                                                onClick={onOptionsClick}
                                            >
                                                <IoEllipsisVertical
                                                    size={primaryIconSize}
                                                    color={colors.grays.NOIR}
                                                    aria-label="Open insight options menu"
                                                />
                                            </IconButton>
                                            <Menu
                                                id="insight-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={onOptionsClose}
                                            >
                                                {mdScreen &&
                                                    props.onOpenFullScreen && (
                                                        <CustomMenuItem
                                                            label="View Fullscreen"
                                                            icon={
                                                                <FullscreenIcon />
                                                            }
                                                            onClick={() => {
                                                                props.onOpenFullScreen?.()
                                                                onOptionsClose()
                                                            }}
                                                        />
                                                    )}
                                                {props.options.length > 0 &&
                                                    props.options.map((o) => (
                                                        <CustomMenuItem
                                                            {...o}
                                                            onClick={(e) => {
                                                                o.onClick?.(e)
                                                                onOptionsClose()
                                                            }}
                                                            key={o.label}
                                                        />
                                                    ))}
                                            </Menu>
                                        </div>
                                    )}
                                </div>
                                {props.subline && (
                                    <Typography>{props.subline}</Typography>
                                )}
                                {props.demographic_mode && (
                                    <div
                                        className={
                                            localClasses.insightDemographicModeTagContainer
                                        }
                                    >
                                        <InsightDemographicModeTag
                                            mode={props.demographic_mode}
                                        />
                                    </div>
                                )}
                                {/* Bar Graph `Show All` Switch */}
                                {showSwitch && (
                                    <Box margin={'-.25rem 0 -1.75rem .5rem'}>
                                        <Switch
                                            checked={showAllData}
                                            onChange={handleShowAll}
                                            label="Show All"
                                            size="small"
                                            name="Show all data switch"
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) : null}
                        <Box
                            className={classes.insight}
                            height={
                                props.type === 'line-fill' ? '100%' : undefined
                            }
                        >
                            {hasData || props.show_if_no_data ? (
                                props.type === 'bar' ? (
                                    <BarGraphInsightView
                                        {...props}
                                        showAllData={showAllData}
                                    />
                                ) : props.type === 'line' ? (
                                    <LineGraphInsightView {...props} />
                                ) : props.type === 'donut' ? (
                                    <DonutChartInsightView {...props} />
                                ) : props.type === 'line-fill' ? (
                                    <LineFillInsightView {...props} />
                                ) : props.type === 'multi-counter' ? (
                                    <MultiCounterInsightView {...props} />
                                ) : props.type === 'quick-stat' ? (
                                    <QuickStatInsightView {...props} />
                                ) : null
                            ) : (
                                <Typography margin="auto">
                                    Not enough data
                                </Typography>
                            )}
                        </Box>
                        {props.ctas && props.ctas.length > 0 && (
                            <div
                                className={classNames(
                                    classes.buttonGroup,
                                    classes.insightCtas
                                )}
                            >
                                {props.ctas.map((cta, index) => (
                                    <Button
                                        key={`${index + 1}-${cta.label
                                            .toLowerCase()
                                            .replaceAll(' ', '-')}`}
                                        onClick={() =>
                                            props.handleCtaClick?.(cta.href)
                                        }
                                        variant="contained"
                                        className={classes.smallButton}
                                    >
                                        {cta.label}
                                    </Button>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    insightDemographicModeTagContainer: {
        alignSelf: 'flex-end',
    },
})
