import Skeleton from '@mui/material/Skeleton'
import { useWindowSizeUp } from '../../../lib/utils'
import { makeStyles } from 'tss-react/mui'

export const InsightsPageButtonsSkeleton = () => {
    const lgScreens = useWindowSizeUp('lg')
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.container}>
            <Skeleton
                variant="rounded"
                sx={{
                    borderRadius: '3rem',
                    height: '2.5rem',
                    marginBottom: '.25rem',
                    width: lgScreens ? '8rem' : '11rem',
                }}
            />
            {lgScreens && (
                <Skeleton
                    variant="rounded"
                    sx={{
                        borderRadius: '3rem',
                        marginBottom: '.25rem',
                        height: '2.5rem',
                        width: '2.5rem',
                    }}
                />
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: { display: 'flex', gap: '0.25rem' },
})
