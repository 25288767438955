import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Mail from '@mui/icons-material/Mail'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import React, { FC, useState } from 'react'

import logo from '../../logo.svg'
import {
    AppVersionNumber,
    Checkbox,
    CompanyLogo,
    Counter,
    DatePicker,
    Dialog,
    EditButton,
    FileUpload,
    Popover,
    LinearGradient,
    Markdown,
    Picker,
    ProgressBar,
    PublicAppIcon,
    RadioButton,
    RPLogo,
    Searchbar,
    Switch,
    TextField,
} from '../atoms'
import { colors } from '@hazadapt-git/public-core-base'
import { ToggleButtons } from '../molecules'

interface AtomsProps {}

export const Atoms: FC<AtomsProps> = (props: AtomsProps) => {
    const [date, setDate] = useState<Date | null>(null)
    const [checked, setChecked] = useState<boolean>(false)
    const [editOn, setEditOn] = useState<boolean>(false)
    const [pickerValue, setPickerValue] = useState<string>('1')
    const [query, setQuery] = useState<string>('')
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const [counterValue, setCounterValue] = useState<number>()

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AppVersionNumber
                </Typography>
                <AppVersionNumber />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Avatar
                </Typography>
                <Avatar src={logo} sx={{ height: '3rem', width: '3rem' }} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Button
                </Typography>
                <Button variant="contained">Test</Button>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Checkbox
                </Typography>
                <Checkbox label="Checkbox" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CompanyLogo
                </Typography>
                <CompanyLogo />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Counter
                </Typography>
                <Counter
                    value={counterValue}
                    min={0}
                    max={99}
                    onChange={setCounterValue}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    DatePicker
                </Typography>
                <DatePicker
                    label="Select date"
                    value={date}
                    onChange={setDate}
                    selectColor={colors.secondary.VIRIDIAN}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Dialog
                </Typography>
                <Button onClick={() => setDialogOpen(true)}>Open</Button>
                <Dialog
                    title="Organization setup is not complete!"
                    content={<img src={logo} alt="React logo" />}
                    ctas={[
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setDialogOpen(false)}
                            key="cancel"
                        >
                            Cancel
                        </Button>,
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setDialogOpen(false)}
                            key="done"
                        >
                            Done
                        </Button>,
                    ]}
                    onClose={() => setDialogOpen(false)}
                    open={dialogOpen}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    EditButton
                </Typography>
                <EditButton
                    editOn={editOn}
                    onClick={() => setEditOn(!editOn)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    FileUpload
                </Typography>
                <FileUpload onFileSelect={() => {}} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HelpIcon + Popover
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography sx={{ paddingTop: 2 }}>
                        Text with help icon
                    </Typography>
                    <Popover helpText="This is a popover." />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LinearGradient
                </Typography>
                <div style={{ display: 'flex' }}>
                    <LinearGradient
                        height={20}
                        width={100}
                        colors={['blue', 'white', 'red']}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Markdown
                </Typography>
                <Markdown
                    content={`### A demo of \`react-markdown\`

\`react-markdown\` is a markdown component for React.

👉 Changes are re-rendered as you type.

👈 Try writing some markdown on the left.`}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Picker
                </Typography>
                <Picker
                    label="Select one..."
                    placeholder="Select one..."
                    data={[
                        { value: '1', label: 'One' },
                        { value: '2', label: 'Two' },
                        { value: '3', label: 'Three' },
                        { value: '4', label: 'Four' },
                        { value: '5', label: 'Five' },
                        { value: '6', label: 'Six' },
                        { value: '7', label: 'Seven' },
                        { value: '8', label: 'Eight' },
                        { value: '9', label: 'Nine' },
                        { value: '10', label: 'Ten' },
                        { value: '11', label: 'Eleven' },
                        { value: '12', label: 'Twelve' },
                        { value: '13', label: 'Thirteen' },
                        { value: '14', label: 'Fourteen' },
                        { value: '15', label: 'Fifteen' },
                        { value: '16', label: 'Sixteen' },
                        { value: '17', label: 'Seventeen' },
                        { value: '18', label: 'Eighteen' },
                        { value: '19', label: 'Nineteen' },
                        { value: '20', label: 'Twenty' },
                        { value: '21', label: 'Twenty-One' },
                        { value: '22', label: 'Twenty-Two' },
                        { value: '23', label: 'Twenty-Three' },
                        { value: '24', label: 'Twenty-Four' },
                        { value: '25', label: 'Twenty-Five' },
                        { value: '26', label: 'Twenty-Six' },
                        { value: '27', label: 'Twenty-Seven' },
                        { value: '28', label: 'Twenty-Eight' },
                        { value: '29', label: 'Twenty-Nine' },
                        { value: '30', label: 'Thirty' },
                        { value: '31', label: 'Thirty-One' },
                        { value: '32', label: 'Thirty-Two' },
                        { value: '33', label: 'Thirty-Three' },
                        { value: '34', label: 'Thirty-Four' },
                        { value: '35', label: 'Thirty-Five' },
                        { value: '36', label: 'Thirty-Six' },
                        { value: '37', label: 'Thirty-Seven' },
                        { value: '38', label: 'Thirty-Eight' },
                        { value: '39', label: 'Thirty-Nine' },
                        { value: '40', label: 'Forty' },
                        { value: '41', label: 'Forty-One' },
                        { value: '42', label: 'Forty-Two' },
                        { value: '43', label: 'Forty-Three' },
                        { value: '44', label: 'Forty-Four' },
                        { value: '45', label: 'Forty-Five' },
                        { value: '46', label: 'Forty-Six' },
                        { value: '47', label: 'Forty-Seven' },
                        { value: '48', label: 'Forty-Eight' },
                        { value: '49', label: 'Forty-Nine' },
                        { value: '50', label: 'Fifty' },
                    ]}
                    selectColor={colors.secondary.BONDI}
                    selectIcon={ArrowDropDown}
                    value={pickerValue}
                    onChange={setPickerValue}
                    bold
                    id="showcase-picker"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ProgressBar
                </Typography>
                <ProgressBar progress={0.75} maxWidth="16rem" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PublicAppIcon
                </Typography>
                <PublicAppIcon />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    RadioButton
                </Typography>
                <RadioButton label="Radio button" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    RPLogo
                </Typography>
                <RPLogo />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Searchbar
                </Typography>
                <div style={{ width: '100%' }}>
                    <Searchbar
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        variant="outlined"
                        options={[]}
                        onOptionPress={() => {}}
                        onQuerySubmit={() => {}}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Switch (a.k.a. Toggle)
                </Typography>
                <Switch
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    label="Switch"
                    color="success"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TextField
                </Typography>
                <TextField
                    icon={<Mail />}
                    label="Email Address"
                    placeholder="Enter email..."
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ToggleButtons
                </Typography>
                <ToggleButtons
                    ariaLabel="Demo Toggle Buttons"
                    leftLabel="Option 1"
                    rightLabel="Option 2"
                    leftIcon={<Mail />}
                    rightIcon={<Mail />}
                    onLeftClick={() => {}}
                    onRightClick={() => {}}
                />
                <Divider />
            </Grid>
        </Grid>
    )
}
