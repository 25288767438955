import {
    colors,
    OrganizationWithMembers,
    RPUser,
} from '@hazadapt-git/public-core-base'
import { useCallback, useEffect, useState } from 'react'
import {
    getFullOrganization,
    inviteUser,
    removeUser,
    resendInvitation,
    toast,
    transferOrgOwnership,
    updateOrganization,
    updateOrganizationLogo,
} from '../utils'
import { errorColor, primaryIconSize, successColor } from '../styles/universal'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { AxiosError } from 'axios'
import { NewRPUser } from '../entities'

export const useOrganization = () => {
    const [organization, setOrganization] =
        useState<OrganizationWithMembers | null>()

    useEffect(() => {
        getFullOrganization()
            .then((org) => {
                setOrganization(org)
            })
            .catch((err) => {
                console.error(err)
                setOrganization(null)
            })
    }, [])

    const handleSaveLogo = useCallback(
        async (file: File) => {
            if (!organization) return
            try {
                const updatedOrg = await updateOrganizationLogo(file)
                setOrganization(updatedOrg)
            } catch (err) {
                console.error(err)
            }
        },
        [organization]
    )

    const handleSubmitNewUser = useCallback(
        async (newUser: NewRPUser) => {
            if (!organization) return
            inviteUser([newUser])
                .then((members) => {
                    toast(
                        `${newUser.first_name} ${newUser.last_name} has been invited to your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members,
                    })
                })
                .catch((err) => {
                    console.error(err)
                    toast(
                        `There was a problem inviting ${newUser.first_name} ${newUser.last_name} to your organization. Please try again.`,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                })
        },
        [organization, setOrganization]
    )

    const handleRemoveUser = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                const newMemberList = await removeUser([user.rp_user_id])
                if (newMemberList) {
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members: newMemberList,
                    })
                    toast(
                        `${user.first_name} ${user.last_name} has been removed from your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                }
            } catch (e) {
                toast(
                    `There was a problem removing ${user.first_name} ${user.last_name} from your organization. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization, setOrganization]
    )

    const handleTransferOwnership = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                const newMemberList = await transferOrgOwnership(
                    user.rp_user_id
                )
                if (newMemberList) {
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members: newMemberList,
                    })
                    toast(
                        `${user.first_name} ${user.last_name} is now the owner of this space!`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                }
            } catch (err) {
                const error = err as AxiosError
                console.error(error)
                toast(
                    error.message ||
                        'Unable to transfer ownership. Please try again.',
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization, setOrganization]
    )

    const handleResendInvite = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            if (user.status === 'active') {
                toast(
                    `${user.first_name} ${user.last_name} has already joined the organization.`
                )
                return
            }
            if (user.status !== 'invited') return
            try {
                const members = await resendInvitation(user.rp_user_id)
                const org = { ...organization }
                setOrganization({
                    ...org,
                    members,
                })
                toast(
                    `${user.first_name} ${user.last_name} has been re-sent an invitation to your organization.`,
                    <IoCheckmarkCircle
                        color={successColor}
                        size={primaryIconSize}
                    />
                )
            } catch (err) {
                console.error(err)
                toast(
                    `There was a problem re-sending the invitation to ${user.first_name} ${user.last_name}. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization, setOrganization]
    )

    const propagateChanges = useCallback(
        async (organization: OrganizationWithMembers) => {
            toast(
                'Saving...',
                <IoCloudUploadOutline
                    color={colors.primary.BLUEBERRY}
                    size={primaryIconSize}
                />
            )
            try {
                const updatedOrg = await updateOrganization(organization)
                setOrganization(updatedOrg)
                toast(
                    'Changes saved!',
                    <IoCheckmark size={primaryIconSize} color={successColor} />
                )
            } catch (err) {
                const error = err as AxiosError
                console.error(error)
                toast(
                    error.message,
                    <IoAlertCircle size={primaryIconSize} color={errorColor} />
                )
            }
        },
        [setOrganization]
    )

    const handleSaveUserChanges = useCallback(
        async (user: RPUser) => {
            if (!organization) return
            const members = [...organization.members]
            const idx = members.findIndex(
                (m) => m.rp_user_id === user.rp_user_id
            )
            if (idx < 0) return
            members[idx] = {
                ...members[idx],
                ...user,
            }
            await propagateChanges({
                ...organization,
                members,
            })
        },
        [organization, propagateChanges]
    )

    return {
        organization,
        setOrganization,
        handleSaveLogo,
        handleRemoveUser,
        handleTransferOwnership,
        handleResendInvite,
        handleSaveUserChanges,
        handleSubmitNewUser,
    }
}
