import { LineFillInsight, colors } from '@hazadapt-git/public-core-base'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface LineFillInsightViewProps
    extends Omit<LineFillInsight, 'headline'> {
    headline: React.ReactNode
}

export const LineFillInsightView: FC<LineFillInsightViewProps> = (
    props: LineFillInsightViewProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.lineFillInsightView}>
            {props.lines.map((line) => (
                <div
                    key={line.id}
                    className={localClasses.lineFillRowContainer}
                >
                    <Typography fontWeight={500}>{line.label}</Typography>
                    <Box
                        className={localClasses.lineFillLineContainer}
                        sx={{ border: `1px ${line.color} solid` }}
                    >
                        <div className={localClasses.lineFillLabels}></div>
                        <LinearProgress
                            variant="determinate"
                            value={line.value * 100}
                            className={localClasses.lineFillLine}
                            sx={{
                                backgroundColor: colors.grays.BLANC,
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: line.color,
                                },
                            }}
                        />
                    </Box>
                    <Typography fontWeight={500} alignSelf="flex-end">
                        {line.valueLabel}
                    </Typography>
                </div>
            ))}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    lineFillInsightView: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginBottom: '2rem',
    },
    lineFillRowContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    lineFillLineContainer: {
        flexGrow: 1,
        width: '100%',
        position: 'relative',
        borderRadius: '0.25rem',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
    },
    lineFillLine: {
        gridColumn: 1,
        gridRow: 1,
        height: '100%',
        width: '100%',
    },
    lineFillLabels: {
        zIndex: 2,
        gridColumn: 1,
        gridRow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.5rem',
        padding: '0.5rem',
    },
})
