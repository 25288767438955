import React, { FC, useEffect } from 'react'
import { NamespacedPageProps } from '../lib/entities'
import { Navigate, Route, Routes } from 'react-router'
import { getSubscriptions } from '../lib/utils'
import { LoadingView } from '../components'
import { SubscriptionsOverview } from '../components/organisms/SubscriptionsOverview'
import { ManageSubscriptionsPage } from './ManageSubscriptions'
import { useOrganization } from '../lib/hooks/useOrganization'

interface SubscriptionsAndBillingPageProps extends NamespacedPageProps {}

const NewPaymentMethodPage = React.lazy(() => import('./NewPaymentMethod'))
export const SubscriptionsAndBillingPage: FC<
    SubscriptionsAndBillingPageProps
> = ({ user, loading }) => {
    const { organization } = useOrganization()

    useEffect(() => {
        getSubscriptions()
    }, [])

    React.useEffect(() => {
        document.title = 'Subscriptions and Billing - ResiliencePoint'
    }, [])

    if (!user || !organization) {
        return !loading && (user === null || organization === null) ? null : (
            <LoadingView />
        )
    }

    return (
        <Routes>
            <Route
                path=""
                element={
                    <SubscriptionsOverview
                        user={user}
                        organization={organization}
                        loading={loading}
                    />
                }
            />
            <Route
                path="payment-methods/new"
                element={
                    <NewPaymentMethodPage
                        user={user}
                        organization={organization}
                        loading={loading}
                    />
                }
            />
            <Route
                path="core/*"
                element={
                    <ManageSubscriptionsPage
                        subscriptionType="core"
                        organization={organization}
                    />
                }
            />
            <Route
                path="seats/*"
                element={
                    <ManageSubscriptionsPage
                        subscriptionType="seat"
                        organization={organization}
                    />
                }
            />
            <Route
                path="*"
                element={<Navigate replace to="/settings/subscriptions" />}
            />
        </Routes>
    )
}
