import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import React from 'react'
import { theme } from '../../lib/styles/universal'
import { InsightGrid } from './InsightGrid'
import {
    QueryBuilder,
    QueryBuilderSkeleton,
    QueryBuilderValues,
} from './QueryBuilder'
import {
    colors,
    // InsightSortMethod,
    Organization,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import {
    InsightViewProps,
    LastUpdatedWithReload,
    LocalContentDataDisclaimer,
} from '../molecules'
import { makeStyles } from 'tss-react/mui'
import { Popover } from '../atoms'
import { useWindowSizeUp } from '../../lib/utils'
import { InsightsLoadingOverlay } from './InsightsLoadingOverlay'

export interface InsightsPageProps {
    pageTitle: string
    organization: Organization
    showLocalContentDisclaimer?: boolean
    queryItemSelectorPlaceholder?: string
    selectedQueryItem?: number
    selectedAreas: string[]
    areaSelectorPlaceholder?: string
    queryId?: number
    queryItemTitle: string
    queryItems: PickerItem<number | undefined>[]
    startDate: Date | null
    endDate: Date | null
    onReloadPress(): void
    updatedDate?: Date
    onRunQueryPress(values: QueryBuilderValues): void
    onResetQueryPress(): void
    onSaveQueryPress: React.MouseEventHandler
    onDownloadPress: React.MouseEventHandler
    selectedQueryString: string
    selectedLocationString: string
    selectedDatesString: string
    insights?: InsightViewProps[]
    quickStatRowTitle?: string
    loading?: boolean
    minQueryDate: Date
    // TODO: Restore for insight sorting */
    // availableSortMethods?: PickerItem<InsightSortMethod>[]
    // setInsightSortMethod?: (sort_method: InsightSortMethod) => void
    // sortMethod?: InsightSortMethod
}

export const InsightsPage = (props: InsightsPageProps) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')
    const { onResetQueryPress } = props

    React.useEffect(() => {
        if (props.loading) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [props.loading])

    // Resets query when user clicks link to page they are currently on
    React.useEffect(() => {
        const handlePageRefresh = () => onResetQueryPress()
        window.addEventListener('page-refresh', handlePageRefresh)

        return () => {
            window.removeEventListener('page-refresh', handlePageRefresh)
        }
    }, [onResetQueryPress])

    return (
        <div className={localClasses.insightsPage}>
            {props.loading && <InsightsLoadingOverlay />}
            <div className={localClasses.header}>
                <div className={localClasses.titleAndQueryBuilder}>
                    <div className={localClasses.title}>
                        <Typography
                            variant={mdScreens ? 'h2' : 'h3'}
                            component="h1"
                            display="flex"
                            flexDirection="column"
                        >
                            {props.pageTitle}
                            <Typography
                                variant={mdScreens ? 'h3' : 'h4'}
                                component="span"
                            >
                                {props.organization.name}
                            </Typography>
                        </Typography>
                        {props.pageTitle === 'Prep Check Activity' ? (
                            <div style={{ transform: 'translateY(-.5rem)' }}>
                                <Popover
                                    popoverPlacement="right"
                                    helpText={`HazAdapt's Prep Checks are self-assessment that can help individuals measure three vital aspects of personal coping capacity for each hazard: knowledge, assets, and networks. Read more about the research behind Prep Checks and how we score coping capacity [here](https://www.hazadapt.com/our-products/about-prep-checks).`}
                                />
                            </div>
                        ) : null}
                    </div>
                    <LastUpdatedWithReload
                        date={props.updatedDate}
                        onReload={props.onReloadPress}
                    />
                    <div className={localClasses.queryBuilderContainer}>
                        {props.loading ? (
                            <QueryBuilderSkeleton />
                        ) : (
                            <QueryBuilder
                                organization={props.organization}
                                pickerOneLabel={props.queryItemTitle}
                                pickerOneValue={props.selectedQueryItem}
                                pickerOnePlaceholder={
                                    props.queryItemSelectorPlaceholder
                                }
                                pickerOneOptions={props.queryItems}
                                areaPickerLabel="Area"
                                selectedAreas={props.selectedAreas}
                                areaPickerPlaceholder={
                                    props.areaSelectorPlaceholder
                                }
                                startDate={props.startDate}
                                endDate={props.endDate}
                                minDate={props.minQueryDate}
                                onRunQueryPress={props.onRunQueryPress}
                                onResetQueryPress={props.onResetQueryPress}
                                onSaveQueryPress={props.onSaveQueryPress}
                                onDownloadPress={props.onDownloadPress}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={localClasses.insightGridContainer}>
                {props.loading ? (
                    <Stack gap="0.5rem">
                        <Skeleton
                            variant="rounded"
                            width="min(80vw, 20rem)"
                            height={theme.typography.h3.lineHeight}
                        />
                        <Skeleton
                            variant="rounded"
                            width="min(80vw, 30rem)"
                            height={theme.typography.h4.lineHeight}
                        />
                    </Stack>
                ) : (
                    <div>
                        <Typography variant="h3" fontWeight={600}>
                            {props.selectedQueryString}
                        </Typography>
                        <Typography variant="h4">
                            {props.selectedLocationString} |{' '}
                            {props.selectedDatesString}
                        </Typography>
                    </div>
                )}
                {!props.loading && props.showLocalContentDisclaimer && (
                    <LocalContentDataDisclaimer />
                )}
                <InsightGrid
                    data={props.insights}
                    quickStatRowTitle={props.quickStatRowTitle}
                    // TODO: Restore for insight sorting */
                    // availableSortMethods={props.availableSortMethods}
                    // setInsightSortMethod={props.setInsightSortMethod}
                    // sortMethod={props.sortMethod}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    insightsPage: {
        position: 'relative',
    },
    header: {
        display: 'flex',
        padding: '1.25rem 0',
        gap: '1rem',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    titleAndQueryBuilder: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        flex: 1,
        padding: '0 1rem',
        gap: '0.5rem',
    },
    mdTitleAndQueryBuilder: {
        gridTemplateColumns: '1fr auto',
        gap: '1rem',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('lg')]: {
            gridColumn: 'unset',
        },
    },
    queryInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '1rem',
        gap: '1rem',
    },
    queryBuilderContainer: {
        [theme.breakpoints.up('lg')]: { gridColumn: '1 / span 2' },
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1',
            justifySelf: 'center',
        },
    },
    insightGridContainer: {
        padding: '1rem 0',
        margin: '0 1rem',
        borderTop: `1px ${colors.grays.NOIR} solid`,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    queryCtas: {
        height: '3rem',
    },
})
