import {
    CommunityAdoptionInterval,
    CommunityAdoptionStat,
    Organization,
    PickerItem,
    RPUser,
} from '@hazadapt-git/public-core-base'
import { SxProps, Theme } from '@mui/system'
import React from 'react'
import Stripe from 'stripe'

export const acceptedImageTypes = '.jpg,.jpeg,.svg,.png,.heic'

export interface PageProps {
    loading: boolean
    desktopMenuOpen?: boolean
}
export interface NamespacedPageProps extends PageProps {
    organization?: Organization | null
    user?: RPUser | null
    subscriptions?: Stripe.Subscription[] | []
    customer?: Stripe.Customer | null
}

export interface NavBarItem {
    label: string
    path: string
    icon?: React.ReactNode
    hide?: boolean
}

export type IconSize = 'small' | 'medium' | 'large' | 'xlarge'

export interface MenuItemProps {
    label: string
    icon?: React.ReactNode
    onClick?: React.MouseEventHandler
    external?: boolean
    sx?: SxProps<Theme>
    danger?: boolean
}

export type GroupViewType = 'card' | 'list'

export type NewRPUser = Pick<
    RPUser,
    'first_name' | 'last_name' | 'email' | 'permissions'
> &
    Partial<RPUser>

export const TIME_INTERVALS: PickerItem<CommunityAdoptionInterval>[] = [
    {
        label: 'Today',
        value: CommunityAdoptionInterval.TODAY,
    },
    {
        label: 'This week',
        value: CommunityAdoptionInterval.WEEK,
    },
    {
        label: 'This month',
        value: CommunityAdoptionInterval.MONTH,
    },
    {
        label: 'This quarter',
        value: CommunityAdoptionInterval.QUARTER,
    },
    {
        label: 'This year',
        value: CommunityAdoptionInterval.YEAR,
    },
    {
        label: 'In total',
        value: CommunityAdoptionInterval.ALL_TIME,
    },
]

export const COMMUNITY_ADOPTION_STATS: PickerItem<CommunityAdoptionStat>[] = [
    {
        label: 'created a HazAdapt profile',
        value: CommunityAdoptionStat.REGISTERED_PROFILES,
    },
    {
        label: 'HazAdapt has been opened',
        value: CommunityAdoptionStat.TOTAL_OPENS,
    },
]

export const rubikFontUrl =
    'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'

export const insightLoadingBlurbsInitial: string[] = [
    'Go hydrate; this takes a sip.',
    'Data-driven resilience is just a moment away.',
    'Every insight counts and yours are on the way!',
    'Spinning up the data tornado; brace for impact!',
]

export const insightLoadingBlurbs7Sec: string[] = [
    '"How are you?" can be a loaded question. So is this page. Loaded with insight.',
    'Still loading? Think of this as a moment of zen.',
    'Just a little longer; our data wizards are casting spells.',
    "Resilience isn't built in a day, but your data is almost done!",
]

export const insightLoadingBlurbs15Sec: string[] = [
    'This page loads using the "slower is faster" method.',
    "Bear with us, we're encouraging the hamsters to spin the wheel as fast as they can.",
    'Bear with us. Community resilience is complex and so is this data.',
    'Slow is smooth. Smooth is fast. We like smooth.',
    'Your patience is the real MVP.',
    "It's all in the journey. Stay resilient through this waiting-on-data-to-load journey.",
    'Just think of all the things you can accomplish while you wait! Now drop and give me 15!',
]

export interface RPPaymentMethod {
    repr: Stripe.PaymentMethod
    id: string
    type: string
    last4: string | null
    expirationDate: {
        str: string | null
        obj: Date | null
    }
    icon: React.ReactNode
    date_created: number
    default?: boolean
}

export const DEFAULT_ANIMATION_SPEED = 400

export const MAX_UNRESOLVED_SEAT_REMINDER_COUNT = 3
