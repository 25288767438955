import cx from 'classnames'
import React, { FC, useMemo } from 'react'
import { MenuItemProps, RPPaymentMethod } from '../../lib/entities'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { IoEllipsisVertical, IoStar } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { CustomMenuItem } from '../../lib/entities/misc'
import { colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import { differenceInMonths } from 'date-fns'
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'

interface PaymentMethodDetailsProps extends RPPaymentMethod {
    actions?: MenuItemProps[]
    className?: string
    hideDefaultTag?: boolean
}

export const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = ({
    icon,
    type,
    last4,
    default: isDefault = false,
    expirationDate,
    actions = [],
    className,
    hideDefaultTag = false,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    const monthsTillExpiration = useMemo(() => {
        if (!expirationDate.str || !expirationDate.obj) return 12 // No expiration date; return a month value that would not be flagged as expiring soon

        return differenceInMonths(expirationDate.obj, new Date())
    }, [expirationDate])

    return (
        <div
            className={classNames(
                localClasses.container,
                { [localClasses.showDefaultTag]: !hideDefaultTag },
                className
            )}
        >
            {isDefault && !hideDefaultTag ? (
                <Tooltip title="This is your organization's default payment method.">
                    <Chip
                        variant="outlined"
                        color="primary"
                        icon={<IoStar />}
                        label="Default"
                        className={localClasses.defaultPaymentMethodIcon}
                    />
                </Tooltip>
            ) : null}
            {icon}
            <Stack>
                <Typography fontWeight={500}>
                    {type}
                    {last4 ? ` - Ending in ${last4}` : null}
                </Typography>
                {expirationDate.str && (
                    <Typography
                        className={cx({
                            [localClasses.expiringSoon]:
                                monthsTillExpiration <= 6,
                        })}
                    >
                        {monthsTillExpiration >= 0 ? 'Expires' : 'Expired'}{' '}
                        {expirationDate.str}
                    </Typography>
                )}
            </Stack>
            {actions.length > 0 ? (
                <>
                    <IconButton
                        onClick={onOptionsClick}
                        className={localClasses.options}
                    >
                        <IoEllipsisVertical
                            size={primaryIconSize}
                            color={colors.grays.NOIR}
                        />
                    </IconButton>
                    <Menu
                        id="payment-method-options"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onOptionsClose}
                    >
                        {actions.map((action) => (
                            <CustomMenuItem
                                {...action}
                                onClick={(e) => {
                                    action.onClick?.(e)
                                    onOptionsClose()
                                }}
                                key={action.label}
                            />
                        ))}
                    </Menu>
                </>
            ) : null}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        position: 'relative',
    },
    showDefaultTag: {
        padding: '2.5rem',
    },
    options: {
        alignSelf: 'flex-start',
        marginTop: '-0.5rem',
        position: 'absolute',
        top: '1rem',
        right: 0,
    },
    expiringSoon: {
        color: colors.primary.CORAL,
        fontWeight: 500,
    },
    defaultPaymentMethodIcon: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        border: 'none',
    },
})
